import React from "react";
import tw, { styled, css } from "twin.macro";
import { Link } from "gatsby";
import { createHTML } from "../utils";

import Button from "./Button";

const Wrapper = styled.div`
  ${tw`
        max-w-screen-lg
    `}
`;

const Item = styled.div`
  ${tw`
        grid md:grid-cols-2
        mx-auto
        items-center
        gap-12
    `}

  &:not(:first-of-type) {
    ${tw`
            mt-28 md:mt-16
        `}
  }

  @media (max-width: 768px) {
    &:nth-child(even) > div:first-child {
      ${tw`
                order-2
            `}
    }
  }
`;

const ImgContainer = styled.div`
  > svg,
  img {
    ${tw`w-full h-auto`}
  }

  @media (max-width: 768px) {
    ${tw`mx-auto`}
  }
`;

const HeaderLink = styled(Link)`
  ${({ to }) =>
    to &&
    css`
      :hover {
        color: ${(props) => props.hoverColor || "darkorange"};
      }
    `}
`;

const Header = styled.div`
  ${tw`
        text-xl font-semibold
        inline-block
    `}

  color: ${({ color }) => color || "black"}
`;

const Body = styled.div`
  ${tw`my-4`}
`;

const BodyList = styled.ul`
  ${tw`list-disc ml-4`}
`;

const BodyListItem = styled.li`
  ${tw`my-2`}
`;

const StyledLink = styled(Link)`
  ${tw`
      text-[darkorange] hover:underline
  `}
`;

const ModifiedButton = styled(Button)`
  ${({ color }) =>
    color &&
    css`
      ${tw`
            border-2
        `}

      background-color: ${color};
      border-color: ${color};

      :hover {
        ${tw`bg-white`}

        color: ${color};
      }
    `}
`;

// if want to use flex-wrap instead of grid (i.e. more than 2 buttons per row)
const ButtonGroup = styled.div`
  ${tw`
    flex flex-wrap
    w-[calc(100% + 1rem)] -ml-2 -mt-2
  `}

  ${({ length, justify }) =>
    length > 1 &&
    css`
      justify-content: ${justify};
    `}
`;

const ShowcaseInternalLink = styled(Link)`
  ${tw`m-2`}

  ${({ icon }) =>
    icon
      ? css`
          ${tw`h-10`}

          > img {
            ${tw`h-full w-full grayscale hover:grayscale-0`}
          }
        `
      : css`
          ${tw`w-[calc(50% - 1rem)]`}

          > ${ModifiedButton} {
            ${tw`w-full h-full flex gap-2 justify-center`}

            > img {
              filter: brightness(0%) invert(1);
              ${tw`h-5 w-5`}
            }

            &:hover > img {
              filter: brightness(100%);
            }
          }
        `}
`;

const ShowcaseExternalLink = styled.a`
  ${tw`m-2`}

  ${({ icon }) =>
    icon
      ? css`
          ${tw`h-10`}

          > img {
            ${tw`h-full w-full grayscale hover:grayscale-0`}
          }
        `
      : css`
          ${tw`w-[calc(50% - 1rem)]`}

          > ${ModifiedButton} {
            ${tw`w-full h-full flex gap-2 justify-center`}

            > img {
              filter: brightness(0%) invert(1);
              ${tw`h-5 w-5`}
            }

            &:hover > img {
              filter: brightness(100%);
            }
          }
        `}
`;

const AlternatingIconTextList = ({ data, linkHoverColor }) => {
  return (
    <Wrapper>
      {data &&
        data.map(
          (
            { main, sub, color, list, url, justifyUrlList, urlList, image },
            idx
          ) => (
            <Item key={idx}>
              {idx % 2 === 0 && (
                <ImgContainer>
                  <img
                    src={
                      image.relativePath ? `/img/${image.relativePath}` : image
                    }
                    alt={`Visual representation of ${main}`}
                  />
                </ImgContainer>
              )}
              <div>
                <HeaderLink to={url} hoverColor={linkHoverColor}>
                  <Header color={color}>{main}</Header>
                </HeaderLink>
                {sub && <Body dangerouslySetInnerHTML={{ __html: sub }} />}
                {list && (
                  <BodyList>
                    {list.map(({ text, url: listItemUrl }) => (
                      <BodyListItem>
                        <div dangerouslySetInnerHTML={createHTML(text)} />
                        {listItemUrl && (
                          <StyledLink to={listItemUrl}>Learn More</StyledLink>
                        )}
                      </BodyListItem>
                    ))}
                  </BodyList>
                )}
                {url && (
                  <Link to={url}>
                    <ModifiedButton color={linkHoverColor}>
                      Learn More
                    </ModifiedButton>
                  </Link>
                )}
                {urlList && (
                  <ButtonGroup length={urlList.length} justify={justifyUrlList}>
                    {urlList.map(({ label, to, icon, tooltip }) => {
                      let isIconBtn = icon && !label;
                      if (to.startsWith("http")) {
                        return (
                          <ShowcaseExternalLink
                            href={to}
                            target="_blank"
                            icon={isIconBtn}
                            title={isIconBtn ? tooltip : ""}
                          >
                            {isIconBtn ? (
                              <img
                                src={
                                  icon.relativePath
                                    ? `/img/${icon.relativePath}`
                                    : icon
                                }
                              />
                            ) : (
                              <ModifiedButton color={color}>
                                {icon && (
                                  <img
                                    src={
                                      icon.relativePath
                                        ? `/img/${icon.relativePath}`
                                        : icon
                                    }
                                  />
                                )}
                                <span>{label}</span>
                              </ModifiedButton>
                            )}
                          </ShowcaseExternalLink>
                        );
                      } else {
                        return (
                          <ShowcaseInternalLink
                            to={to}
                            icon={isIconBtn}
                            title={isIconBtn ? tooltip : ""}
                          >
                            {isIconBtn ? (
                              <img
                                src={
                                  icon.relativePath
                                    ? `/img/${icon.relativePath}`
                                    : icon
                                }
                              />
                            ) : (
                              <ModifiedButton color={color}>
                                {icon && (
                                  <img
                                    src={
                                      icon.relativePath
                                        ? `/img/${icon.relativePath}`
                                        : icon
                                    }
                                  />
                                )}
                                <span>{label}</span>
                              </ModifiedButton>
                            )}
                          </ShowcaseInternalLink>
                        );
                      }
                    })}
                  </ButtonGroup>
                )}
              </div>
              {idx % 2 !== 0 && (
                <ImgContainer>
                  <img
                    src={
                      image.relativePath ? `/img/${image.relativePath}` : image
                    }
                    alt={`Visual representation of ${main}`}
                  />
                </ImgContainer>
              )}
            </Item>
          )
        )}
    </Wrapper>
  );
};

export default AlternatingIconTextList;
