import React from 'react'
import tw, { styled, css } from 'twin.macro'
import { Link } from "gatsby"

const Wrapper = styled.div`
    ${tw`
        w-full
    `}
`

const CardsList = styled.ul`
    ${tw`
        grid gap-4 
        grid-cols-1 sm:grid-cols-2
    `}
    ${({ maxColumns }) => maxColumns === 3? tw`lg:grid-cols-3`: tw`lg:grid-cols-4`}
`

const Card = styled.div`
    ${tw`
        flex flex-col flex-wrap
        justify-center items-center 
        relative 
        w-full h-full
        rounded-xl shadow-xl
        border-4 border-opacity-50 border-gray-100
        transition-transform duration-300
        overflow-hidden
    `}

    &:hover {
        ${tw`
            transform -translate-y-2
        `}
    }
`

const CardImg = styled.div`
    ${tw`
        w-full
        p-3
        flex justify-center items-center
    `}

    background-color:  ${props => props.bgColor || "rgba(209, 213, 219, 1)"};

    > svg, img {
        ${tw`
            w-40 h-40
        `}
    }

    ${({ isIcon }) => isIcon && css`
        ${tw`
            py-20
        `}
        
        > svg, img {
            ${tw`
                w-16 h-16
            `}
        }
    `}
`

const CardText = styled.div`
    ${tw`
        w-full p-3
        text-lg text-center font-semibold
        flex-grow
    `}

    ${({ isIcon }) => isIcon && tw`
        text-2xl
    `}
`

const ImageCards = ({ data: cardData }) => {
    const { useIcons, data } = cardData;
    return (
        <Wrapper>
            <CardsList maxColumns={data.length}>
                {data.map(({ text, url, image, bgColor }, idx) => (
                    <li key={idx}>
                        <Link to={url}>
                            <Card>
                                <CardImg bgColor={bgColor} isIcon={useIcons}>
                                    <img src={image.relativePath ? `/img/${image.relativePath}` : image} alt={`${text} icon/visual`} />
                                </CardImg>
                                <CardText isIcon={useIcons}>
                                    {text}
                                </CardText>
                            </Card>
                        </Link>
                    </li>
                ))}
            </CardsList>
        </Wrapper>
    )
}

export default ImageCards