import React, { useContext } from "react";
import tw, { styled, css } from "twin.macro";

import { EmailContext } from "../contexts/EmailContext";

import Button from "./Button";
import SvgMessagesIcon from "./SvgMessagesIcon";

const Wrapper = styled.div`
  ${tw`
        grid gap-6 relative
        text-center
        mx-auto
    `}

  z-index: 2;
`;

const HeaderGrid = styled.div`
  ${tw`
        grid gap-2
    `}

  > svg {
    ${tw`
            h-10 w-auto mx-auto
        `}
  }
`;

const HeaderPretext = styled.p`
  ${tw`
        text-xl font-semibold
        tracking-wider uppercase
    `}
`;

const HeaderMaintext = styled.h3`
  ${tw`
        font-bold
    `}
`;

const Subheader = styled.p`
  ${tw`
        text-lg
    `}
`;

const ContactButton = styled(Button)`
  ${tw`
        w-max mx-auto
    `}
`;

const ContactUs = ({
  iconColor,
  header,
  subheader,
  buttonLabel,
  formType = [], // is an array of length 1 (DecapCMS list custom types)
}) => {
  const { setShowEmail, setType, setPreSelectedServices } =
    useContext(EmailContext);

  let typeObj = {};
  if (Array.isArray(formType)) typeObj = formType[0] || {};

  return (
    <Wrapper>
      <HeaderGrid>
        <SvgMessagesIcon color={iconColor} />
        <HeaderPretext>LT3 ATG</HeaderPretext>
        <HeaderMaintext>{header || "Ready to get started?"}</HeaderMaintext>
      </HeaderGrid>
      {subheader !== "" && (
        <Subheader>
          {subheader ||
            "Building your vision starts with a conversation. We're always happy to chat."}
        </Subheader>
      )}
      <ContactButton
        handleClick={() => {
          setShowEmail(true);
          setType(typeObj.type || "general");
          setPreSelectedServices(typeObj.preSelectedServices || []);
        }}
      >
        {buttonLabel || "Let's Chat!"}
      </ContactButton>
    </Wrapper>
  );
};

export default ContactUs;
