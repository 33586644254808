import React from "react";
import tw, { styled } from "twin.macro";
import { createHTML } from "../utils";

const Wrapper = styled.div`
  ${tw``}
`;

const FlexRow = styled.div`
  ${tw`
        flex flex-wrap 
        w-full justify-around
    `}
`;

const FlexCol = styled.div`
  ${tw`
        flex flex-col 
        gap-2 items-center
        p-6
    `}

  @media (min-width: 768px) {
    flex-basis: calc(100% / 2);
  }

  @media (min-width: 1024px) {
    flex-basis: calc(100% / 4);
  }
`;

const HeaderContainer = styled.div`
  ${tw`w-full`}

  > h6 {
    ${tw`
        font-semibold
        mb-1
    `}
  }

  > img {
    ${tw`
        w-16 h-16
        mb-5 mx-auto
    `}
  }
`;

const Body = styled.div`
  ${tw`text-base`}
`;

const InlineIconTextList = ({ data, className }) => {
  return (
    <Wrapper className={className}>
      <FlexRow>
        {data &&
          data.map(({ main, sub, image }, dataIdx) => (
            <FlexCol>
              <HeaderContainer>
                <img
                  src={
                    image.relativePath ? `/img/${image.relativePath}` : image
                  }
                  alt={`${main} icon`}
                />
                <h6>{main}</h6>
              </HeaderContainer>
              <Body dangerouslySetInnerHTML={createHTML(sub)} />
            </FlexCol>
          ))}
      </FlexRow>
    </Wrapper>
  );
};

export default InlineIconTextList;
