import React from "react";
import tw, { styled } from "twin.macro";

const Wrapper = styled.div`
  ${tw`
        max-w-screen-lg
    `}
`;
const Header = styled.h3`
  ${tw`
        text-center font-bold mb-16
    `}

  ${({ hasSub }) =>
    hasSub &&
    tw`
        mb-12
    `}
`;

const Subheader = styled.p`
  ${tw`
        text-lg
    `}
`;

const ListItem = styled.div`
  ${tw`
        grid md:grid-cols-2
        mx-auto
        items-center
        gap-12
        mt-8
    `}

  &:not(:first-of-type) {
    ${tw`
            mt-28 md:mt-16
        `}
  }

  @media (max-width: 768px) {
    &:nth-child(even) > div:first-child {
      ${tw`order-2 `}
    }
  }
`;

const ImgContainer = styled.div`
  > svg,
  img {
    ${tw`w-full h-auto`}
  }

  @media (max-width: 768px) {
    ${tw`mx-auto`}
  }
`;

const ListMain = styled.div`
  ${tw`font-bold mb-2`}
`;

const FocalPoints = ({ header, subheader, data }) => {
  return (
    <Wrapper>
      <Header hasSub={subheader !== ""}>{header}</Header>
      {subheader !== "" && <Subheader>{subheader}</Subheader>}
      {data &&
        data.map((item, idx) => (
          <ListItem>
            {idx % 2 === 0 && (
              <ImgContainer>
                <img
                  src={
                    item.image.relativePath
                      ? `/img/${item.image.relativePath}`
                      : item.image
                  }
                  alt={`Visual representation of ${item.main}`}
                />
              </ImgContainer>
            )}
            <div key={idx}>
              <ListMain>{item.main}</ListMain>
              <div>{item.sub}</div>
            </div>
            {idx % 2 !== 0 && (
              <ImgContainer>
                <img
                  src={
                    item.image.relativePath
                      ? `/img/${item.image.relativePath}`
                      : item.image
                  }
                  alt={`Visual representation of ${item.main}`}
                />
              </ImgContainer>
            )}
          </ListItem>
        ))}
    </Wrapper>
  );
};

export default FocalPoints;
