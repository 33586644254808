import React from "react";
import tw, { styled } from "twin.macro";

const Container = styled.div`
  ${tw`flex gap-y-4 md:gap-x-4 flex-col md:flex-row`}
`;

const Card = styled.div`
  ${tw`flex flex-col p-4 md:p-8 shadow-xl rounded`}
`;

const AuthorContainer = styled.div`
  ${tw`flex items-center my-4 gap-x-4`}
`;
const Avatar = styled.div`
  ${tw`h-16 w-16 rounded-full overflow-hidden`}

  > img {
    ${tw`object-cover w-full h-full`}
  }
`;
const Name = styled.div`
  ${tw``}
`;

const Title = styled.div`
  ${tw`text-gray-500`}
`;

const Testimonials = ({ data }) => (
  <Container>
    {data.map(({ text, author, title }, key) => (
      <Card key={key}>
        <div>{text}</div>
        <AuthorContainer>
          {/* <Avatar>
            <img
              src={
                item.avatar.relativePath
                  ? `/img/${item.avatar.relativePath}`
                  : item.avatar
              }
              alt={`testimonial icon`}
            />
          </Avatar> */}
          <div>
            {author && <Name>{author}</Name>}
            <Title>{title}</Title>
          </div>
        </AuthorContainer>
      </Card>
    ))}
  </Container>
);

export default Testimonials;
