import React from 'react'

const SvgMessagesIcon = ({ color }) => {
    return (
        <svg width="512" height="493" viewBox="0 0 512 493" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 135V270H30.5H61V306.5C61 326.575 61.198 343 61.44 343C61.683 343 85.643 326.575 114.685 306.5L167.488 270H279.244H391V135V0H195.5H0V135ZM301 105V120H196H91V105V90H196H301V105ZM301 165V180H196H91V165V150H196H301V165ZM421 225V300H298.935H176.871L148.945 319.25L121.018 338.5L121.009 379.25L121 420H232.756H344.512L397.315 456.5C426.357 476.575 450.317 493 450.56 493C450.802 493 451 476.575 451 456.5V420H481.5H512V285V150H466.5H421V225ZM421 345V360H316H211V345V330H316H421V345Z"
                fill={color}
            />
        </svg>
    )
}

export default SvgMessagesIcon

SvgMessagesIcon.defaultProps = {
    color: "#FF8C00"
}