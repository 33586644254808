import React, { useState, useEffect } from 'react'
import tw, { styled, css } from 'twin.macro'
import { createHTML } from '../utils'

const Wrapper = styled.div`
    ${tw`
        py-24 
        relative
        w-full
        overflow-hidden
    `}

    > img {
        ${tw`
            absolute top-0 bottom-0
            h-full w-auto
            opacity-60 lg:opacity-100
            transition-all duration-700
        `}

        :first-child {
            ${tw`
                left-0
                right-0 sm:right-auto
                m-auto
            `}
        }

        :nth-child(2) {
            ${tw`
                right-0
            `}

            @media (max-width: 640px) {
                ${tw`
                    opacity-0
                `}
            }
        }
    }
`

const TextContent = styled.div`
    ${tw`
        grid gap-6
        relative
        text-center
        my-0 mx-auto
        max-w-screen-md
    `}

    z-index: 2;
`

const HeaderContainer = styled.div`
    ${tw`
        grid gap-2
    `}
`

const PreHeader = styled.p`
    ${tw`
        text-lg font-semibold 
        tracking-wider uppercase
        mx-auto
    `}

    max-width: 40rem;
`

// This takes on the size of an h3 as per the custom global styles but every page needs to have an h1 for SEO optimization
const Header = styled.h1`
    ${tw`
        font-extrabold
        tracking-tight
        bg-clip-text text-transparent

        text-4xl sm:text-5xl
        leading-tight sm:leading-tight      
    `}

    background-image: linear-gradient(orange, orangered);

    ${({ gradientTop, gradientBottom }) => gradientTop && gradientBottom && css`
        background-image: linear-gradient(${gradientTop}, ${gradientBottom});
    `}

`

const Body = styled.div`
    ${tw`
        text-xl
        my-0 mx-auto
        whitespace-pre-wrap
    `}

    max-width: 40rem;
`

const Hero = ({ data: { preheader, header, subheader, sideImages } }) => {
    return (
        <Wrapper>
            <img src={sideImages.left.relativePath ? `/img/${sideImages.left.relativePath}` : sideImages.left} alt={`Left header visual`} />
            <img src={sideImages.right.relativePath ? `/img/${sideImages.right.relativePath}` : sideImages.right} alt={`Right header visual`} />
            <TextContent>
                <HeaderContainer>
                    <PreHeader>{preheader}</PreHeader>
                    <Header dangerouslySetInnerHTML={createHTML(header.text)} gradientTop={header.gradient.top} gradientBottom={header.gradient.bottom} />
                </HeaderContainer>
                <Body dangerouslySetInnerHTML={createHTML(subheader)} />
            </TextContent>
        </Wrapper>
    )
}

export default Hero