import React from "react";
import { graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import { createHTML } from "../utils";

import Layout from "../components/Layout";
import Section from "../components/Section";
import InlineIconTextList from "../components/InlineIconTextList";
import Hero from "../components/Hero";
import ImageCards from "../components/ImageCards";
import Testimonials from "../components/Testimonials";
import ContactUs from "../components/ContactUs";
import AlternatingIconTextList from "../components/AlternatingIconTextList";
import FocalPoints from "../components/FocalPoints";

const StyledSection = styled(Section)`
  ${({ noMb }) => noMb && tw`mb-0`}
`;

const Header = styled.h3`
  ${tw`
        text-center font-bold mb-16
    `}

  ${({ hasSub }) =>
    hasSub &&
    tw`
        mb-12
    `}
`;

const Subheader = styled.h6`
  ${tw`
        text-center mb-16
    `}
`;

const WhyWeAreDifferent = styled(InlineIconTextList)`
  ${tw`
        max-w-screen-2xl
        px-6 md:px-12
    `}

  grid-column: full;
`;

const CardsSectionHeader = styled.h3`
  ${tw`
        font-bold
        mb-6
    `}
`;

const CardsSectionLink = styled(Link)`
  ${tw`
        text-lg
        mt-8
        underline
    `}

  &:hover {
    text-decoration-color: darkorange;
  }
`;
const CallToActionContainer = styled.div`
  ${tw`py-2`}
`;

const PricingPage = ({
  data: {
    markdownRemark: {
      frontmatter: {
        seo,
        hero,
        inlineSection,
        listShowcase,
        cardShowcase,
        focalPoints,
        testimonials,
        contactSectionTop,
        contactSectionMid,
        contactSectionBottom,
      },
    },
  },
}) => {
  return (
    <Layout title={seo.title} description={seo.description}>
      <StyledSection noMb={contactSectionTop && contactSectionTop.visible}>
        <Hero data={hero} />
      </StyledSection>

      {contactSectionTop && contactSectionTop.visible && (
        <Section>
          <ContactUs
            iconColor={contactSectionTop.color}
            header={contactSectionTop.header}
            subheader={contactSectionTop.subheader}
            buttonLabel={contactSectionTop.buttonLabel}
            formType={contactSectionTop.formType}
          />
        </Section>
      )}

      <Section>
        <Header>{inlineSection.header}</Header>
        <WhyWeAreDifferent data={inlineSection.data} />
      </Section>

      {contactSectionMid && contactSectionMid.visible && (
        <Section>
          <ContactUs
            iconColor={contactSectionMid.color}
            header={contactSectionMid.header}
            subheader={contactSectionMid.subheader}
            buttonLabel={contactSectionMid.buttonLabel}
            formType={contactSectionMid.formType}
          />
        </Section>
      )}

      {listShowcase && listShowcase.display && (
        <Section>
          <Header hasSub>{listShowcase.header}</Header>
          <Subheader
            dangerouslySetInnerHTML={createHTML(listShowcase.subheader)}
          />
          <AlternatingIconTextList data={listShowcase.data} />
        </Section>
      )}

      {focalPoints && focalPoints.visible && (
        <Section>
          <FocalPoints
            header={focalPoints.header}
            subheader={focalPoints.subheader}
            data={focalPoints.data}
          />
        </Section>
      )}

      {testimonials && testimonials.display && (
        <Section>
          <CardsSectionHeader>{testimonials.title}</CardsSectionHeader>
          <Testimonials data={testimonials.data} />
        </Section>
      )}

      {cardShowcase && cardShowcase.display && (
        <Section>
          <CardsSectionHeader>{cardShowcase.title}</CardsSectionHeader>
          <ImageCards data={cardShowcase.cards} />
          <CardsSectionLink>
            <Link to={cardShowcase.link.url}>{cardShowcase.link.text}</Link>
          </CardsSectionLink>
        </Section>
      )}

      {contactSectionBottom && contactSectionBottom.visible && (
        <Section>
          <ContactUs
            iconColor={contactSectionBottom.color}
            header={contactSectionBottom.header}
            subheader={contactSectionBottom.subheader}
            buttonLabel={contactSectionBottom.buttonLabel}
            formType={contactSectionBottom.formType}
          />
        </Section>
      )}
    </Layout>
  );
};

export default PricingPage;

export const pageQuery = graphql`
  query pricingPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          preheader
          header {
            text
            gradient {
              top
              bottom
            }
          }
          subheader
          sideImages {
            left {
              relativePath
            }
            right {
              relativePath
            }
          }
        }
        contactSectionTop {
          visible
          color
          header
          subheader
          buttonLabel
          formType {
            type
            preSelectedServices
          }
        }
        inlineSection {
          header
          paragraph
          data {
            main
            sub
            image {
              relativePath
            }
          }
        }
        contactSectionMid {
          visible
          color
          header
          subheader
          buttonLabel
          formType {
            type
            preSelectedServices
          }
        }
        focalPoints {
          visible
          header
          subheader
          data {
            main
            sub
            image {
              relativePath
            }
          }
        }
        listShowcase {
          display
          header
          subheader
          data {
            main
            sub
            color
            image {
              relativePath
            }
            justifyUrlList
            urlList {
              label
              to
              icon {
                relativePath
              }
              tooltip
            }
          }
        }
        testimonials {
          display
          title
          data {
            text
            author
            title
            avatar {
              relativePath
            }
          }
        }
        cardShowcase {
          display
          title
          link {
            text
            url
          }
          cards {
            useIcons
            data {
              text
              url
              image {
                relativePath
              }
              bgColor
            }
          }
        }
        contactSectionBottom {
          visible
          color
          header
          subheader
          buttonLabel
          formType {
            type
            preSelectedServices
          }
        }
      }
    }
  }
`;
